import styled from "styled-components";

export const PageJsWrapper = styled.div`
  svg {
    font-family: Inter, Helvetica, "sans-serif";
    width: 100%;
    height: 100%;
  }
  svg text {
    animation: stroke 2.5s infinite alternate;
    stroke-width: 2;
    stroke: #3699ff;
    // font-size: 80px;
  }
  @keyframes stroke {
    0% {
      fill: rgba(230, 51, 42, 0);
      stroke: #3699ff;
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
    }
    70% {
      fill: rgba(230, 51, 42, 0);
      stroke: #3699ff;
    }
    80% {
      fill: rgba(230, 51, 42, 0);
      stroke: #3699ff;
      stroke-width: 3;
    }
    100% {
      fill: #3699ff;
      stroke: rgba(230, 51, 42, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
  }
`;
