import { Checkbox, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import RangePickerESF from "../../../../../component/range-picker";
import {
  DateFormat,
  DefaultPerPage,
  GetMarketplaceList,
} from "../../../../../config";
const CampaignFilters = (props) => {
  const {
    set_selected_currency_sign,
    currency_list,
    all_filters,
    set_all_filters,
    marketplace_list,
    selected_marketplace,
    set_selected_marketplace,
    setLoading,
    GetApiCall,
  } = props;
  return (
    <div className="row">
      <div className="col-12">
        <Checkbox
          onChange={(e) => {
            console.log(e, "-e");
            if (e.target.checked) {
              const apiObj = {
                ...all_filters,
                search_key: "",
                ad_type: null,
                campaign_name: "",
                state: null,
                start_date: dayjs().add(-30, "d"),
                end_date: dayjs(),
              };
              set_all_filters(apiObj);
              setLoading(true);
              GetApiCall(apiObj);
            }
          }}
        />
        <label className="text-primary fw-bold ms-2">Clear all filters</label>
      </div>
      <div className="separator separator-dashed my-4" />
      <div className="col-12 my-3">
        {" "}
        <label className="fw-bold">Date Range</label>
        <RangePickerESF
          className="ant_common_input w-100"
          id="ivcsr_date_picker"
          size="large"
          allowClear={false}
          format={DateFormat}
          value={[all_filters?.start_date, all_filters?.end_date]}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={(e) => {
            const filters_ = all_filters;
            filters_.start_date = e?.[0];
            filters_.end_date = e?.[1];
            set_all_filters({ ...filters_ });
          }}
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold">Campaign Type</label>
        <Select
          options={[
            {
              label: "Sponsored Product",
              value: "SPONSORED_PRODUCTS",
            },
            {
              label: "Sponsored Display",
              value: "SPONSORED_DISPLAY",
            },
            { label: "Sponsored Brand", value: "SPONSORED_BRAND" },
            { label: "Sponsored TV", value: "SPONSORED_TV" },
          ]}
          className="w-100"
          size="large"
          allowClear
          value={all_filters?.ad_type}
          onChange={(e) => {
            const apiObj = {
              ...all_filters,
              ad_type: e,
            };
            set_all_filters(apiObj);
          }}
          placeholder="Campaign Type"
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold">Campaign Status</label>{" "}
        <Select
          options={[
            { label: "PAUSED", value: "PAUSED" },
            { label: "ENABLED", value: "ENABLED" },
          ]}
          className="w-100"
          size="large"
          allowClear
          value={all_filters?.state}
          onChange={(e) => {
            const apiObj = {
              ...all_filters,
              state: e,
            };
            set_all_filters(apiObj);
          }}
          placeholder="Campaign Status"
        />
      </div>
      <div className="col-12 my-3">
        <label className="fw-bold"> Targeting Type</label>
        <Select
          options={[
            { label: "AUTO", value: "AUTO" },
            { label: "MANUAL", value: "MANUAL" },
          ]}
          className="w-100"
          size="large"
          allowClear
          value={all_filters?.targeting_settings}
          onChange={(e) => {
            const apiObj = {
              ...all_filters,
              targeting_settings: e,
            };
            set_all_filters(apiObj);
          }}
          placeholder="Targeting Type"
        />
      </div>
      <div className="col-12 my-3">
        {" "}
        <label className="fw-bold">Marketplace</label>{" "}
        <Select
          className="w-100"
          size="large"
          placeholder="Select Marketplace"
          options={GetMarketplaceList(marketplace_list)}
          value={selected_marketplace}
          onChange={(e) => {
            set_selected_marketplace(e);
            set_selected_currency_sign(currency_list[e]);
            const apiObj = {
              ...all_filters,
              page: 1,
              "per-page": DefaultPerPage,
            };
            set_all_filters(apiObj);
          }}
        />
      </div>
      {/* <div className="col-12 my-5 text-center"></div> */}
    </div>
  );
};

export default CampaignFilters;
