import { GetLinks } from "../../../../core";

export default [
  {
    key: "/advertisement",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-book fs-1" style={{ color: "#B03060" }}></i>
      </span>
    ),
    label: "Advertisement",
    children: [
      {
        key: "/advertisement-dashboard",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/advertisement-dashboard", "Advertisement Dashboard"),
      },
      {
        key: "/campaign-management",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/campaign-management", "Campaign Management"),
      },
      {
        key: "/rule-management",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: "Rule Management",
        children: [
          {
            key: "/rule-management/rule-book",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/rule-management/rule-book", "Rule Book"),
          },
          {
            key: "/rule-management/day-parting",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/rule-management/day-parting", "Day Parting"),
          },
        ],
      },
      // {
      //   key: "/product-details",
      //   icon: (
      //     <span class="menu-icon">
      //       <i class="ki-outline ki-cube-2  fs-1"></i>
      //     </span>
      //   ),
      //   label: GetLinks("/product-details", "Product Details"),
      // },

      {
        key: "/ketword-management",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: "Keyword Management",
        children: [
          {
            key: "/keyword-management/keyword-targeting",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks(
              "/keyword-management/keyword-targeting",
              "Keyword Targeting"
            ),
          },
        ],
      },
      {
        key: "/advertisement-report",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/advertisement-report", "Advertisement Report"),
      },
    ],
  },
];
