import React, { useState } from "react";
import Table2 from "../../../../component/antd-table/table2";
import { Input, Select } from "antd";

const DamagedReturns = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
      key: "returnDate",
    },
    {
      title: "Refund/Replacement Date",
      dataIndex: "refundReplacementDate",
      key: "refundReplacementDate",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Order ID Status",
      dataIndex: "orderIdStatus",
      key: "orderIdStatus",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Detailed Disposition",
      dataIndex: "detailedDisposition",
      key: "detailedDisposition",
    },
    {
      title: "Order Payment Amount",
      dataIndex: "orderPaymentAmount",
      key: "orderPaymentAmount",
    },
    {
      title: "Return Report Status",
      dataIndex: "returnReportStatus",
      key: "returnReportStatus",
    },
    {
      title: "Case ID",
      dataIndex: "caseId",
      key: "caseId",
    },
    {
      title: "Case Status",
      dataIndex: "caseStatus",
      key: "caseStatus",
    },
    {
      title: "RMS ID",
      dataIndex: "rmsId",
      key: "rmsId",
    },
    {
      title: "Quantity Reimbursed Total",
      dataIndex: "quantityReimbursedTotal",
      key: "quantityReimbursedTotal",
    },
    {
      title: "Quantity Reimbursed by Cash",
      dataIndex: "quantityReimbursedCash",
      key: "quantityReimbursedCash",
    },
    {
      title: "Quantity Reimbursed by Inventory",
      dataIndex: "quantityReimbursedInventory",
      key: "quantityReimbursedInventory",
    },
    {
      title: "Credited Amount",
      dataIndex: "creditedAmount",
      key: "creditedAmount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Date Submitted",
      dataIndex: "dateSubmitted",
      key: "dateSubmitted",
    },
    {
      title: "Date Resolved",
      dataIndex: "dateResolved",
      key: "dateResolved",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // render: (_, record) => (
      //   <Button onClick={() => handleAction(record)}>Action</Button>
      // ),
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0">
              <div className="d-flex align-items-center flex-wrap p-2">
                <div className="position-relative">
                  <Input
                    style={{ width: "250px" }}
                    prefix={
                      <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                    }
                    placeholder="Search by FNSKU"
                    size="large"
                  />
                </div>
              </div>
              <div className="card-toolbar">
                <div className="d-flex align-items-center flex-wrap p-2 gap-2">
                  {" "}
                  <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "150px" }}
                      options={[]}
                      placeholder="Transaction Status"
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "150px" }}
                      options={[]}
                      placeholder="Case Id"
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "150px" }}
                      options={[]}
                      placeholder="Manager"
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "150px" }}
                      options={[]}
                      placeholder="Case Status"
                    />
                  </div>
                  <div className="position-relative">
                    <button className="btn btn-secondary">Cases Export</button>
                  </div>
                  <div className="position-relative">
                    <button className="btn btn-light-primary">Export</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body py-2">
              <div className="table-responsive">
                <Table2
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: "max-content" }}
                  loading={tableLoading}
                  // setSortFilters={setSortFilters}
                  // sortFilters={sortFilters}
                  // setSortOrderColumns={setSortOrderColumns}
                  // onSortChange={(sort, currentPage, pageSize, order_column) => {
                  //   setPageSize(pageSize);
                  //   setCurrentPage(currentPage);
                  //   getTableData({
                  //     ...all_filters,
                  //     page: currentPage,
                  //     "per-page": pageSize,
                  //     marketplace_id: selected_marketplace,
                  //     // order_column: order_column,
                  //     // order_value: sort?.startsWith("-")
                  //     //   ? "SORT_ASC"
                  //     //   : "SORT_DESC",
                  //   });
                  // }}
                  // pagination={PaginationConfig({
                  //   pageSize,
                  //   currentPage,
                  //   totalPage,
                  //   onChange: (e, f) => {
                  //     setPageSize(f);
                  //     setCurrentPage(e);
                  //   },
                  // })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DamagedReturns;
