import { FilePdfOutlined } from "@ant-design/icons";
import { message, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MakeApiCall } from "../../../apis/make-api-call";
import { GlobalContext } from "../../../commonContext";
import { DownloadPDF } from "../../../component/download-pdf/download-pdf";
import RangePickerESF from "../../../component/range-picker";
import {
  ConvertParams,
  DateFormat,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../config";
import ActiveKeywordModal from "./lib/active-keyword-modal";
import AdvDahboardCard from "./lib/adv-dahboard-card";
import AdvDetailCard from "./lib/adv-detail-card";
import AdvSalesOverviewChart from "./lib/adv-sales-overview-chart";
const Dashboard = () => {
  const chartRef = useRef(null);
  const contextValue = useContext(GlobalContext);
  const [matchTypeDataFilters, setMatchTypeDataFilters] = useState([]);
  const [selected_marketplace, set_selected_marketplace] = useState([]);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [advTypeDataFilters, setAdvTypeDataFilters] = useState([]);
  const [targetingCardGraphData, setTargetingCardGraphData] = useState([]);
  const [targetingCardData, setTargetingCardData] = useState([]);
  const [performanceMetricsCardData, setPerformanceMetricsCardData] = useState(
    []
  );
  const [advDailyTrendData, setAdvDailyTrendData] = useState([]);
  const [apiResAdvDailyTrends, setApiResAdvDailyTrends] = useState({});
  const [chartXAxis, setchartXAxis] = useState([]);
  const [chartSeries, setchartSeries] = useState([]);

  const [activeCampaignCardData, setActiveCampaignCardData] = useState([]);
  const [exportPerformanceTrendGraph, setExportPerformanceTrendGraph] =
    useState([]);
  const [activeKeywordList, setActiveKeywordList] = useState([]);
  const targetingPDFRef = useRef();
  const campaignPDFRef = useRef();

  // ---------------------------------------------------------------------------------------------------  Storage State

  const [targetingCardLoading, setTargetingCardLoading] = useState(true);
  const [performanceMetricsCardLoading, setPerformanceMetricsCardLoading] =
    useState(true);
  const [
    dailyPerformanceTrendChartLoading,
    setDailyPerformanceTrendChartLoading,
  ] = useState(true);
  const [activeCampaignCardLoading, setActiveCampaignCardLoading] =
    useState(true);
  const [targetingCardGraphLoading, setTargetingCardGraphLoading] =
    useState(true);
  const [isOpenActiveKeywordModal, setIsOpenActiveKeywordModal] =
    useState(false);
  // ---------------------------------------------------------------------------------------------------  Boolean State

  const [all_filter, set_all_filters] = useState({
    match_type: [
      "EXACT",
      "PHRASE",
      "BROAD",
      "TARGETING_EXPRESSION_PREDEFINED",
      "TARGETING_EXPRESSION",
    ],
    matrics: ["roas", "spend", "sales", "acos"],
    advertising_type: ["SP"],
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs().add(-2, "d"),
    request_type: "Current",
    marketplace_id: null,
  });

  // ---------------------------------------------------------------------------------------------------  Static Data State
  const [isCapturing, setIsCapturing] = useState(false);

  useEffect(() => {
    localStorage.setItem("token_type", "");
    console.log(contextValue?.data?.selected_sub_user, "-subUserToken");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setPerformanceMetricsCardLoading(false);
        setActiveCampaignCardLoading(false);
        setTargetingCardGraphLoading(false);
        setTargetingCardLoading(false);
        setDailyPerformanceTrendChartLoading(false);
        setIsOpenActiveKeywordModal(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filter,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetAdvFilters(marketplace?.marketplace_list[0]?.marketplace_id);
    } else {
      setTargetingCardLoading(false);
      setPerformanceMetricsCardLoading(false);
      setActiveCampaignCardLoading(false);
      setTargetingCardGraphLoading(false);
      setDailyPerformanceTrendChartLoading(false);
    }
  };

  const GetAdvFilters = async (marketplace_id) => {
    const response = await MakeApiCall(
      `advertising/dashboard/filters`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      const newMatchTypeData = Object?.entries(
        response?.data?.match_type_data
      )?.map((d) => {
        return { label: d?.[1], value: d?.[0] };
      });
      const newAdvTypeData = Object?.entries(
        response?.data?.advertising_type_data
      )?.map((d) => {
        return { label: d?.[1], value: d?.[0] };
      });
      const newAdvDailyTrendData = Object?.entries(
        response?.data?.metrics_data
      )?.map((d) => {
        return { label: d?.[1], value: d?.[0] };
      });

      setAdvTypeDataFilters(newAdvTypeData);
      setMatchTypeDataFilters(newMatchTypeData);
      const newMetricsData = Object.entries(response?.data?.metrics_data)?.map(
        (d) => {
          return {
            [d?.[1]]: d?.[0],
          };
        }
      );
      const mergedObject = newMetricsData.reduce((acc, obj) => {
        console.log(acc, obj);
        return { ...acc, ...obj };
      }, {});
      setApiResAdvDailyTrends(mergedObject);
      setAdvDailyTrendData(newAdvDailyTrendData);

      const GraphCardpAiObj = {
        ...all_filter,
        marketplace_id: marketplace_id,
        start_date: moment(all_filter?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filter?.end_date?.$d).format("YYYY-MM-DD"),
      };

      setTargetingCardGraphLoading(true);
      GetTargetingCardGraphData(GraphCardpAiObj);

      setTargetingCardLoading(true);
      GetTargetingCardData(GraphCardpAiObj);

      setActiveCampaignCardLoading(true);
      GetActiveCampaignCardData(GraphCardpAiObj);

      setPerformanceMetricsCardLoading(true);
      GetPerformanceMetricsCardData(GraphCardpAiObj);

      setDailyPerformanceTrendChartLoading(true);
      GetDailyPerformanceTrendGraph(GraphCardpAiObj);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetTargetingCardGraphData = async (filters) => {
    const response = await MakeApiCall(
      `advertising/dashboard/dashboard-cards-data${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setTargetingCardGraphData(response?.data?.all_ads_performance_data);
      setTargetingCardGraphLoading(false);
    } else {
      setTargetingCardGraphData({});
      setTargetingCardGraphLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetTargetingCardData = async (filters) => {
    const response = await MakeApiCall(
      `advertising/dashboard/targeting-card-data${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setTargetingCardLoading(false);
      setTargetingCardData(response?.data?.targeting_data);
    } else {
      setTargetingCardLoading(false);
      setTargetingCardData([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetActiveCampaignCardData = async (filters) => {
    const response = await MakeApiCall(
      `advertising/dashboard/active-campaigns${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setActiveCampaignCardLoading(false);
      setActiveCampaignCardData(response?.data);
    } else {
      setActiveCampaignCardLoading(false);
      setActiveCampaignCardData([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetPerformanceMetricsCardData = async (filters) => {
    const response = await MakeApiCall(
      `advertising/dashboard/performance-metrics${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPerformanceMetricsCardLoading(false);
      setPerformanceMetricsCardData(response?.data);
    } else {
      setPerformanceMetricsCardLoading(false);
      setPerformanceMetricsCardData([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetDailyPerformanceTrendGraph = async (filters) => {
    const response = await MakeApiCall(
      `advertising/dashboard/daily-performance-trend${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      const OriginalData = response?.data?.data;
      const chartxAxis = OriginalData?.map((d, i) => {
        return d?.report_date;
      });

      const seriesData = filters?.matrics?.map((a, j) => {
        const seriesName =
          a === "acos"
            ? "ACoS"
            : a === "clicks"
            ? "Clicks"
            : a === "cpc"
            ? "CPC"
            : a === "ctr"
            ? "CTR"
            : a === "cvr"
            ? "CVR"
            : a === "impressions"
            ? "Impressions"
            : a === "roas"
            ? "RoAS"
            : a === "sales"
            ? "Total Sales"
            : a === "spend"
            ? "Spend"
            : a === "unit_ordered"
            ? "Orders"
            : "";
        const localData = OriginalData?.map((d, i) => {
          return parseFloat(d[a]);
        });
        return { name: seriesName, data: localData };
      });
      setDailyPerformanceTrendChartLoading(false);
      setchartSeries(seriesData);
      setchartXAxis(chartxAxis);
      setExportPerformanceTrendGraph(response?.data?.data);
    } else {
      setPerformanceMetricsCardLoading(false);
      setPerformanceMetricsCardData([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetActiveKeywordList = async (filters) => {
    const response = await MakeApiCall(
      `advertising/dashboard/active-keywords${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      if (response?.data?.data?.length !== 0) {
        setIsOpenActiveKeywordModal(true);
        setActiveKeywordList(response?.data?.data);
      } else {
        DisplayNotification("error", "Error", " No data found");
      }
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const boxStyle = isCapturing
    ? {
        background: " #f6f6f6",
        borderRadius: "12px",
        border: "1px solid #e7ebef",
      }
    : {
        background: " #f6f6f6",
        borderRadius: "12px",
        border: "1px solid #e7ebef",
        boxShadow: "0px 0px 7px -5px #035091",
      };

  const DownloadNewPDF = async (captureRef) => {
    message.destroy();
    message.loading("Exporting...", 0);
    console.log(isCapturing, "-isCapturing");
    setTimeout(async () => {
      if (captureRef.current) {
        setIsCapturing(true);
        await new Promise((resolve) => setTimeout(resolve, 0));

        const canvas = await html2canvas(captureRef.current, {
          scale: 2,
        });
        const image = canvas.toDataURL("image/png");

        // Create an image object
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Set crossOrigin to avoid CORS issues

        // Load the image
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          const pdf = new jsPDF({
            orientation: height < width ? "landscape" : "portrait",
            unit: "pt",
            format: [(img.width / 96) * 72 + 20, (img.height / 96) * 72 + 20],
          });
          // Check if the image fits within the available space on the current page
          pdf.addImage(img, "JPEG", 10, 10);
          // pdf.addImage(img, "JPEG", 5, 10, width, height); // Adjust x, y, width, height as needed

          pdf.save("image_to_pdf.pdf");
          setIsCapturing(false);
        };
        img.src = image;
      }
      message.destroy();
    }, 100);
  };

  return (
    <div
      className="container-fluid fadeInLeftAllPage"
      id="kt_content_container"
    >
      <div className="row gy-5 g-xl-5 mb-5">
        <div className="col-xxl-12 px-0 mx-0">
          <div
            className="card"
            style={{ background: " #e9f1fa", border: "1px dashed #3699ff69" }}
          >
            <div className="card-header border-0 d-flex align-items-center">
              <div className="d-flex flex-wrap gap-4">
                <div className="position-relative">
                  <RangePickerESF
                    className="ant_common_input"
                    id="ivcsr_date_picker"
                    size="large"
                    allowClear={false}
                    style={{ width: "290px" }}
                    format={DateFormat}
                    value={[all_filter?.start_date, all_filter?.end_date]}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onChange={(e) => {
                      const filters_ = all_filter;
                      filters_.start_date = e?.[0];
                      filters_.end_date = e?.[1];
                      set_all_filters({ ...filters_ });

                      const GraphCardpAiObj = {
                        ...all_filter,
                        marketplace_id: selected_marketplace,
                        start_date: moment(e?.[0]?.$d).format("YYYY-MM-DD"),
                        end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                      };

                      setTargetingCardGraphLoading(true);
                      GetTargetingCardGraphData(GraphCardpAiObj);

                      setPerformanceMetricsCardLoading(true);
                      GetPerformanceMetricsCardData(GraphCardpAiObj);

                      setActiveCampaignCardLoading(true);
                      GetActiveCampaignCardData(GraphCardpAiObj);

                      setTargetingCardLoading(true);
                      GetTargetingCardData(GraphCardpAiObj);

                      setDailyPerformanceTrendChartLoading(true);
                      GetDailyPerformanceTrendGraph(GraphCardpAiObj);
                    }}
                  />
                </div>
                <div className="position-relative">
                  <Select
                    className="w-200px"
                    size="large"
                    placeholder="Select Marketplace"
                    options={GetMarketplaceList(marketplace_list)}
                    value={selected_marketplace}
                    onChange={(e) => {
                      set_selected_marketplace(e);
                      set_selected_currency_sign(currency_list[e]);
                      const GraphCardpAiObj = {
                        ...all_filter,
                        marketplace_id: e,
                      };
                      set_all_filters(GraphCardpAiObj);

                      setTargetingCardGraphLoading(true);
                      GetTargetingCardGraphData(GraphCardpAiObj);

                      setPerformanceMetricsCardLoading(true);
                      GetPerformanceMetricsCardData(GraphCardpAiObj);

                      setActiveCampaignCardLoading(true);
                      GetActiveCampaignCardData(GraphCardpAiObj);

                      setTargetingCardLoading(true);
                      GetTargetingCardData(GraphCardpAiObj);

                      setDailyPerformanceTrendChartLoading(true);
                      GetDailyPerformanceTrendGraph(GraphCardpAiObj);
                    }}
                  />
                </div>
                <div className="position-relative">
                  <Select
                    className="w-200px"
                    size="large"
                    placeholder="Select"
                    mode="multiple"
                    onChange={(e) => {
                      set_all_filters({
                        ...all_filter,
                        match_type: e,
                      });
                      const GraphCardpAiObj = {
                        ...all_filter,
                        match_type: e,
                        start_date: moment(all_filter?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: moment(all_filter?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                      };

                      setTargetingCardGraphLoading(true);
                      GetTargetingCardGraphData(GraphCardpAiObj);
                    }}
                    maxTagCount={"responsive"}
                    value={all_filter?.match_type}
                    options={matchTypeDataFilters?.map((d) => {
                      return { label: d?.label, value: d?.value };
                    })}
                  />
                </div>
                <div className="position-relative">
                  <Select
                    className="w-200px"
                    size="large"
                    placeholder="Select"
                    mode="multiple"
                    onChange={(e) => {
                      let selectedOpt = e;
                      if (e?.length == 0) {
                        selectedOpt = "SP";
                      }
                      set_all_filters({
                        ...all_filter,
                        advertising_type: selectedOpt,
                      });
                      const GraphCardpAiObj = {
                        ...all_filter,
                        advertising_type: selectedOpt,
                        start_date: moment(all_filter?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: moment(all_filter?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                      };
                      setPerformanceMetricsCardLoading(true);
                      GetPerformanceMetricsCardData(GraphCardpAiObj);

                      setActiveCampaignCardLoading(true);
                      GetActiveCampaignCardData(GraphCardpAiObj);

                      setTargetingCardLoading(true);
                      GetTargetingCardData(GraphCardpAiObj);

                      setDailyPerformanceTrendChartLoading(true);
                      GetDailyPerformanceTrendGraph(GraphCardpAiObj);

                      // const subDashboardApiObj = {
                      //   marketplace_id: selected_marketplace,
                      //   request_type: "Current",
                      //   advertising_type: e,
                      //   start_date: moment(filters?.start_date?.$d).format(
                      //     "YYYY-MM-DD"
                      //   ),
                      //   end_date: moment(filters?.end_date?.$d).format(
                      //     "YYYY-MM-DD"
                      //   ),
                      // };
                      // setDashboardSubCardLoading(true);
                      // setActiveCampaignCardLoading(true);
                      // setTargetingCardLoading(true);

                      // GetAdvDashboardSubCardData(subDashboardApiObj);
                      // GetActiveCampaignSubCardData(subDashboardApiObj);
                      // GetTargetingCardData(subDashboardApiObj);

                      // const dailyTrendObj = {
                      //   marketplace_id: selected_marketplace,
                      //   request_type: "Current",
                      //   advertising_type: e,
                      //   matrics: matrics,
                      //   start_date: moment(filters?.start_date?.$d).format(
                      //     "YYYY-MM-DD"
                      //   ),
                      //   end_date: moment(filters?.end_date?.$d).format(
                      //     "YYYY-MM-DD"
                      //   ),
                      // };
                      // setDailyPerformanceTrendChartLoading(true);
                      // GetDailyPerformanceTrend(dailyTrendObj);
                    }}
                    maxTagCount={"responsive"}
                    value={all_filter?.advertising_type}
                    options={advTypeDataFilters?.map((d) => {
                      return { label: d?.label, value: d?.value };
                    })}
                  />
                </div>
              </div>
              <div className="card-toolbar">
                {/* <span
                  className="btn  fs-7 btn-primary fw-bold"
                  onClick={() => {}}
                >
                  Add New Rule
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gy-5 g-xl-5" ref={targetingPDFRef}>
        <div className="col-xxl-12 px-0 mx-0">
          <div className="card card-xxl-stretch mb-5 mb-xl-5">
            <div className="card-header pt-5 border-bottom-0">
              <label className="card-title align-items-start flex-column">
                <span className="fw-bold text-dark fs-2">Targeting</span>
              </label>

              <div className="card-toolbar">
                <span
                  className="d-flex align-items-center cursor-pointer me-5"
                  onClick={() => {
                    DownloadPDF(targetingPDFRef);
                  }}
                >
                  <Tooltip title="Download PDF" placement="bottom">
                    <FilePdfOutlined
                      className="fs-1"
                      style={{ color: "red" }}
                    />
                  </Tooltip>
                </span>
              </div>
            </div>

            <div className="card-body py-3">
              <AdvDahboardCard
                currency_sign={selected_currency_sign}
                targetingCardGraphData={targetingCardGraphData}
                targetingCardGraphLoading={targetingCardGraphLoading}
                targetingCardLoading={targetingCardLoading}
                targetingCardData={targetingCardData}
                chartRef={chartRef}
                GetActiveKeywords={() => {
                  const filterObj = {
                    ...all_filter,
                    marketplace_id: selected_marketplace,
                    start_date: moment(all_filter?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: moment(all_filter?.start_date?.$d).format(
                      "YYYY-MM-DD"
                    ),
                  };
                  GetActiveKeywordList(filterObj);
                }}
                start_date={moment(all_filter?.start_date?.$d).format(
                  "MMM DD, YYYY"
                )}
                end_date={moment(all_filter?.end_date?.$d).format(
                  "MMM DD, YYYY"
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="separator separator-dashed my-1"
        style={{ borderColor: "#3699ff" }}
      />
      <div className="row gy-5 g-xl-5" ref={campaignPDFRef}>
        <div className="col-xxl-12 px-0 mx-0">
          {" "}
          <div className="card card-xxl-stretch mb-5 mb-xl-5 mt-5">
            <div className="card-header pt-5 border-bottom-0">
              <label className="card-title align-items-start flex-column">
                <span className="fw-bold text-dark fs-2">Campaign</span>
              </label>

              <div className="card-toolbar">
                {" "}
                <span
                  className="d-flex align-items-center cursor-pointer me-5"
                  onClick={() => {
                    DownloadNewPDF(campaignPDFRef);
                  }}
                >
                  <Tooltip title="Download PDF" placement="bottom">
                    <FilePdfOutlined
                      className="fs-1"
                      style={{ color: "red" }}
                    />
                  </Tooltip>
                </span>
              </div>
            </div>

            <div className="card-body py-3">
              <div className="row mb-5">
                <AdvDetailCard
                  currency_sign={selected_currency_sign}
                  activeCampaignCardData={activeCampaignCardData}
                  activeCampaignCardLoading={activeCampaignCardLoading}
                  performanceMetricsCardData={performanceMetricsCardData}
                  performanceMetricsCardLoading={performanceMetricsCardLoading}
                  boxStyle={boxStyle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="separator separator-dashed my-5"
        style={{ borderColor: "#3699ff" }}
      />

      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12 px-0 mx-0">
          {" "}
          <AdvSalesOverviewChart
            currency_sign={selected_currency_sign}
            apiResAdvDailyTrends={apiResAdvDailyTrends}
            selectedFilter={all_filter?.matrics}
            setSelectedFilter={(filter) => {
              set_all_filters({
                ...all_filter,
                matrics: filter,
              });
            }}
            chartSeries={chartSeries}
            chartXAxis={chartXAxis}
            advDailyTrendData={advDailyTrendData}
            dailyPerformanceTrendChartLoading={
              dailyPerformanceTrendChartLoading
            }
            setDailyPerformanceTrendChartLoading={
              setDailyPerformanceTrendChartLoading
            }
            exportPerformanceTrendGraph={exportPerformanceTrendGraph}
            onChangeMatricsFilter={(e) => {
              setchartSeries([]);
              setchartXAxis([]);
              const GraphCardpAiObj = {
                ...all_filter,
                matrics: e,
                marketplace_id: selected_marketplace,
                start_date: moment(all_filter?.start_date?.$d).format(
                  "YYYY-MM-DD"
                ),
                end_date: moment(all_filter?.end_date?.$d).format("YYYY-MM-DD"),
              };

              setDailyPerformanceTrendChartLoading(true);
              GetDailyPerformanceTrendGraph(GraphCardpAiObj);
            }}
          />
        </div>
      </div>
      {isOpenActiveKeywordModal && (
        <ActiveKeywordModal
          currency_sign={selected_currency_sign}
          show={isOpenActiveKeywordModal}
          onHide={() => setIsOpenActiveKeywordModal(false)}
          activeKeywordList={activeKeywordList}
        />
      )}
    </div>
  );
};

export default Dashboard;
