// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/reimbursements",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-dollar fs-1" style={{ color: "#FF00FF" }}></i>
      </span>
    ),
    label: GetLinks("/reimbursements", "Reimbursements"),
  },
];
