// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/report-center",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-minus-folder  fs-1"
          style={{ color: "#9ACD32" }}
        ></i>
      </span>
    ),
    label: GetLinks("/report-center", "Report Center"),
  },
];
