import advertisementModule from "./advertisement-module";
import applicationLogs from "./application-logs";
import extraTools from "./extra-tools";
import inventoryManagement from "./inventory-management";
import listingOptimizer from "./listing-optimizer";
import manageUser from "./manage-user";

import productDetails from "./product-details";
import productResearch from "./product-research";
import reimbursements from "./reimbursements";
import reportCenter from "./report-center";
import setting from "./setting";
import dashboard from "./dashboard";
const usertype = localStorage.getItem("user_type");
const payload = {
  users: [
    ...dashboard,
    ...productDetails,
    ...advertisementModule,
    // ...campaignManagement,
    // ...ruleBook,
    // ...ruleManagement,
    // ...masterTable,

    // ...recommendationAlerts,
    // ...keywordManagement,
    // ...advertisementReport,
    ...listingOptimizer,
    ...reimbursements,
    ...productResearch,
    ...inventoryManagement,
    ...extraTools,
    ...reportCenter,
    ...setting,
    ...applicationLogs,
    // ...userAccounts,
    // ...dayParting
  ],
  admin: [...manageUser],
};

export default payload;
