import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import dashboard from "./dashboard";
import manageUser from "./manage-user";
import setting from "./setting";
import applicationLog from "./application-log";
import campaignManagement from "./campaign-management";
import masterTable from "./master-table";
import ruleManagement from "./rule-management";
import advertisementReport from "./advertisement-report";
import keywordManagement from "./keyword-management";
import ruleBook from "./rule-book";
import dayParting from "./day-parting";
import userAccounts from "./user-accounts";
import reimbursement from "./reimbursement";
// export const Routes_ =
//   localStorage.getItem("user_type") == 1 ||
//     ? [...manageUser]
//     : [...manageUser, ...catelogManagement, ...setting];

// export const Routes_ = [...manageUser, ...catelogManagement, ...setting];
const PageRouter = () => {
  const Routes_ =
    localStorage.getItem("user_type") == 1
      ? [...manageUser]
      : [
          ...dashboard,
          ...campaignManagement,
          ...masterTable,
          ...setting,
          ...applicationLog,
          ...ruleManagement,
          // ...ruleBook,
          ...reimbursement,
          ...advertisementReport,
          ...keywordManagement,
          ...userAccounts,
          // ...dayParting
        ];

  // const Routes_ = [...manageUser, ...dashboard];

  return (
    <Routes>
      {Routes_?.map((d, i) => {
        return (
          <Route
            key={i}
            path={d?.path}
            element={d?.component}
            exact={d?.exact}
          />
        );
      })}

      {/* <Route path="*" render={() => <div>Error Page</div>} /> */}
      {/* <Route path="*" render={() => <Error />} /> */}
    </Routes>
  );
};

export default PageRouter;
